.widget-payment {
  height: 100%;
  box-sizing: border-box;
  padding: 70px 32px;
  background-color: #f5f5f5;

  * {
    font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
      Roboto, Arial, sans-serif;
  }
}

.payment-bill {
  &__subtitle {
    margin: 50px 0 24px;
    font-size: 16px;
    line-height: 1;
    color: #333;
  }

  &__table--without-title {
    margin-top: 50px;
  }

  &__status-cards {
    padding: 0 24px;
    background: #f0f0f0;
    border-radius: 3px;

    .status-card {
      padding: 24px 0;
      border-bottom: 1px dashed #e6e6e6;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.payment-bill-section {
  &__separator {
    border: 0;
    border-top: 1px solid #e6e6e6;
    margin-bottom: 24px;
  }

  &__item {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;

    &--total {
      font-size: 18px;
      font-weight: 400;
      color: #333;
    }

    &-label {
      max-width: 60%;
      line-height: normal;

      &--overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:last-child #{&}__item {
    margin-bottom: 0;
    padding-bottom: 24px;
  }
}

.purchase-amount {
  &__installments {
  }

  &__symbol {
    margin-right: 3px;
  }

  &__fraction {
  }

  &__cents {
    font-size: 10px;
    vertical-align: top;
  }

  &__interest {
  }

  &--total #{&}__cents {
    font-size: 12px;
  }
}
