.widget-purchase {
  height: 100%;
  box-sizing: border-box;
  padding: 70px 32px;
  background-color: #f5f5f5;

  * {
    font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
      Roboto, Arial, sans-serif;
  }

  &__pack-title {
    font-size: 14px;
    line-height: 0.86;
    color: #999;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .widget-package-detail {
    margin-bottom: 24px;
  }
}

.widget-package-detail,
.widget-sell-detail {
  background-color: #fafafa;
  padding: 24px;
  border-radius: 3px;

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 0.86;

    &--info,
    &--default {
      color: #000;
    }

    &--success {
      color: #00a650;
    }

    &--warning {
      color: #f73;
    }

    &--error {
      color: #f23d4f;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }
}

.widget-shipping-details {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 3px;
  display: flex;

  svg {
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 1.29;

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    color: #3a3a3a;
  }

  &__subtitle {
    font-weight: 300;
    color: #666;
  }
}
