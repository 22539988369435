@import '~@andes/card/index';

.suggestion-search {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 6px 16px 0 #0000001a;

  &__title {
    box-sizing: border-box;
    color: #8c8c8c;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    padding: 16px;
  }

  &__container {
    height: 100%;
  }

  &__list {
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  &__item {
    list-style: none;
  }

  &__fill {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;

    &-icon {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 8px;
      width: 24px;
      height: 24px;
      transform: rotate(45deg);
      background: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M6 15.667l9 9L16.333 23 10 16.667h16v-2H10l6.333-6.333L15 6.667z" fill="%23999999"/>%3C/svg>')
        center center no-repeat;
      background-size: cover;
    }
  }

  &__link {
    text-decoration: none;
    display: block;
    position: relative;
    padding: 16px 40px 20px 49px;
    margin: 0;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    line-height: 0.88;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #333;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 1em;
      top: 0.9em;
      background: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path d="M398.725 689.682c156.825 0 284.95-128.125 284.95-284.95 0-157.85-128.125-284.95-284.95-284.95s-284.95 127.1-284.95 284.95c0 156.825 128.125 284.95 284.95 284.95zm0 56.375c-188.6 0-341.325-152.725-341.325-341.325S210.125 63.407 398.725 63.407 740.05 216.132 740.05 404.732 587.325 746.057 398.725 746.057zm235.75-65.6l248.05 248.05 39.976-39.975-19.474-20.5-227.55-227.55-20.5-19.475L615 660.982l19.476 19.475z" fill="%23999999"/>%3C/svg>')
        center center no-repeat;
      background-size: cover;
      width: 18px;
      height: 18px;
    }

    &--visited::before {
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 26.656c5.888 0 10.656-4.768 10.656-10.656S21.888 5.344 16 5.344C10.112 5.344 5.344 10.112 5.344 16S10.112 26.656 16 26.656zm0 1.792C9.12 28.448 3.552 22.88 3.552 16S9.12 3.552 16 3.552c6.88 0 12.448 5.568 12.448 12.448S22.88 28.448 16 28.448zm0-10.656h6.208V16h-7.104v1.792H16zm-.896-8.896v8h1.792V8h-1.792v.896z" fill="%23999999" />%3C/svg>');
    }

    &--selected {
      background: #f5f5f5;
    }

    strong {
      font-weight: 400;
    }
  }
}
