@import '~@andes/form/index';
@import '~@andes/textfield/index';

$bkg-search-icon: '../../assets/svg/search.svg';

.search-box {
  .andes-form-control__control {
    .andes-form-control__field {
      background-image: url($bkg-search-icon);
      background-position: 10px 50%;
      background-repeat: no-repeat;
      padding-left: 40px;
      min-width: 188px;
      box-sizing: content-box;
    }
  }

  .andes-form-control__bottom {
    display: none;
  }
}

.cx-search__cancel-button_desktop:hover {
  svg {
    path {
      fill: $andes-blue-500;
      transition: 0.2s;
    }
  }
}

.cx-search-button {
  height: 50px;
  margin-top: -1px;
  border: 0;
}

.cx-search-box {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .cx-input-search {
    margin-right: 12px;
    width: 100%;

    .input-search__container {
      max-width: 278px;
    }
  }

  .andes-form-control--default.cx-input-search {
    padding-top: 0;
  }

  .andes-form-control__bottom {
    display: none;
  }

  .andes-form-control__control {
    .andes-form-control__field {
      background-position: 10px 50%;
      background-repeat: no-repeat;
      min-width: 230px;
      width: 100%;
    }
  }
}

.cx-search {
  &__cancel-button {
    background: transparent;
    border: none;
    height: 36px;
    box-sizing: border-box;
    margin: 6px 0;
    padding: 0 4px 0 16px;
    display: flex;
    align-items: center;

    &_desktop {
      border-left: 1px solid rgb(0 0 0 / 20%);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (width <= 768px) {
  .cx-search-box .cx-input-search {
    margin-right: 0;
  }
}

@media screen and (width <= 1025px) {
  .cx-search-button {
    display: none;
  }
}
