.button-native {
  padding: 15px 16px 13px;
  border-radius: 24px;
  font-size: 16px;
  border: none;
  background: white;
  display: flex;
  color: rgb(0 0 0 / 90%);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 25%);

  .placeholder {
    font-family: 'Proxima Nova', -apple-system, Roboto, Arial, sans-serif,
      sans-serif;
    color: rgb(0 0 0 / 60%);
    margin-left: 0.5rem;
  }
}
