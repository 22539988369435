/* stylelint-disable max-nesting-depth */
.cx-card-list {
  .andes-list__item.cx-card-list__item.andes-list__item--size-medium.andes-list__item-with-secondary {
    height: auto;
  }

  &__card-item {
    margin-bottom: 8px;

    .cx-card-list__item {
      cursor: pointer;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: auto;

      // .andes-list__item-primary {
      //   font-weight: 600;
      // }

      .andes-list__item-secondary {
        margin-top: 4px;
        line-height: 1.24;
      }
    }

    .circled-icon {
      border: solid 1px rgb(0 0 0 / 5%);
      border-radius: 100%;
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.cx-peach-home__section {
  .cx-card-list {
    &__card-item {
      .cx-card-list__item {
        &--regulatory-text {
          .andes-list__item-secondary {
            color: #171717;
          }
        }
      }
    }
  }
}

.cx-card-list--in-folder {
  margin-bottom: 16px;

  .cx-card-list {
    &__card-item {
      padding: 0 16px;

      .circled-icon {
        display: none;
      }

      .cx-card-list__item {
        padding: 0;
      }
    }
  }
}

.cx-new-card-list {
  .cx-card-list__card-item {
    margin-bottom: 1.2rem;

    .andes-list__item-primary {
      font-weight: 600;
    }

    .andes-list__item-secondary {
      @media screen and (width <= 768px) {
        font-size: 12px;
      }
    }
  }
}

@media screen and (width >= 768px) {
  .cx-card-list {
    &__card-item {
      li.cx-card-list__item {
        position: relative;
        transition: background-color 0.1s ease-out;

        &:hover {
          background-color: #f5f5f5;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          background-color: #3483fa;
          opacity: 0;
          transition: opacity 0.1s ease-out;
        }

        &:hover::before {
          opacity: 1;
        }

        .andes-list__item-primary .cx-card-list__item--title {
          color: #3483fa;
        }
      }
    }
  }
}
