.widget-product {
  height: 100%;
  box-sizing: border-box;
  padding: 70px 32px;
  background-color: #f5f5f5;
}

.product-card {
  border-radius: 3px;
  background-color: #fafafa;
  padding: 24px 24px 16px;
  margin-bottom: 32px;

  &__label-status {
    font-size: 14px;
    font-weight: 600;
    color: #3a3a3a;
    margin: 0;
    line-height: 1;
    margin-bottom: 16px;

    &--success {
      color: #00a650;
    }

    &--warning {
      color: #f73;
    }

    &--error {
      color: #f23d4f;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }
}

.product-summary {
  list-style: none;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed #e6e6e6;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__image {
    border-radius: 50%;
    margin-right: 16px;
    overflow: hidden;
    width: 48px;
    height: 48px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__data {
    flex-grow: 1;
    overflow: auto;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1;
    color: #3a3a3a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .purchase-amount {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.17;
    color: #666;

    &__cents {
      font-size: 8px;
    }
  }

  &__quantity {
    margin-left: 4px;
  }
}
