.peach__activities__card {
  margin-bottom: 20px;

  .andes-card__content {
    padding: 0;
  }

  .andes-card__footer {
    padding: 0;
    overflow: hidden;

    .andes-list__item {
      cursor: pointer;

      .andes-list__item-primary {
        color: $andes-blue-500;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700 !important;
      }
    }
  }

  .c-activities__list {
    padding: 0;
    margin: 0;
    list-style: none;

    .c-activity-row--classic .ui-row__link {
      padding-right: 16px;

      .ui-action-row__actions-toggle {
        display: none;
      }
    }

    .c-activity-row__extra-action.c-activity-row__extra-action--margin {
      padding: 0;
    }
  }
}
