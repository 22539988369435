.widget-collapsible {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding-top: 24px;

  &__title {
    cursor: pointer;
    outline: 0;
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: normal;
    color: #333;
    padding-bottom: 24px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__dropdown-arrow {
    position: relative;
    display: inline-block;
    width: 0.66667em;
    height: 0.44444em;
    pointer-events: none;
    background: transparent;
    margin-left: 8px;
    transition: transform 0.35s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: -0.11111em;
      left: 0.11111em;
      width: 0.33333em;
      height: 0.33333em;
      background: inherit;
      border-right: 0.11111em solid #3483fa;
      border-bottom: 0.11111em solid #3483fa;
      transform: rotate(45deg);
      transition: border-color 0.18s ease-out;
      box-sizing: content-box;
    }

    &--active {
      transform: rotate(-180deg);
    }
  }
}
