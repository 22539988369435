/* stylelint-disable selector-max-id */
@import '~@andes/common/index';
@import './variables';
@import '../components/Widget/widgetTypes/RelatedContents/styles';
@import '../components/SuggestionSearch/styles';
@import '../components/Breadcrumb/styles';

@import '~@andes/card/index';
@import '~@andes/search-box/index';
@import '~@andes/form/base';
@import '~@andes/typography/index';

#root-app {
  display: flex;
  flex: 1;

  &.mp-main__content {
    position: static;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.cx-peach-layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $body_bg_color_gray;
  height: auto;
  flex-grow: 1;

  &__wrapper {
    flex: 1;
  }

  &__home {
    .portal-content {
      align-items: center;
    }
  }

  &__landing {
    width: 100%;
  }

  &__portal {
    width: 100vw;
    display: flex;
    justify-content: space-between;

    .cx-peach__widget {
      background-color: #f5f5f5;
      min-width: 438px;
      width: 438px;
    }
  }

  .portal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 746px;
    width: calc(100vw - 534px);
    margin: 0 auto;
  }

  .portal-content.portal-content__congrats {
    max-width: unset;
    height: 100%;
    width: 100%;
  }
}

.cx-peach-layout.layout-portal {
  background-color: $body_bg_color_gray;
}

.section-title {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
}

.section-subtitle {
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgb(0 0 0 / 45%);
}

.box-restriction {
  margin-top: 20px;
}

.box-restriction h2 {
  color: #aa8749;
  font-size: 20px;
  margin: 0 0 10px;
}

.cx-peach-content {
  &__title {
    font-size: 24px;
    font-weight: 600;
    padding: 0 0 26px;
    line-height: 1.2em;
    margin: 0;
  }
}

@media screen and (width >= 768px) {
  .mobile-header {
    display: none;
  }

  .section-title {
    display: flex;
    color: rgb(0 0 0 / 70%);
  }

  .section-subtitle {
    display: flex;
    height: 20px;
  }
}

@media screen and (width <= 768px) {
  .cx-peach-layout {
    .portal-content {
      width: auto;
      margin: 0 auto;
    }

    &__portal {
      justify-content: center;

      .portal-content {
        padding: 0 20px 24px;
      }

      .cx-peach__widget {
        display: none;
      }
    }

    &.layout-portal {
      background-color: $body_bg_color_gray;
    }
  }

  .section-title {
    font-size: 20px;
    line-height: 1.08;
  }

  .mobile-header {
    .view-title {
      font-size: 24px;
      line-height: 1.08;
      color: rgb(0 0 0 / 80%);
      margin-top: 0;
    }
  }
}

@media (width >= 1024px) {
  .nav-footer-info {
    width: 100%;
    padding: 0 0 18px;
    display: flex;
    justify-content: space-between;

    .nav-footer-navigation {
      float: left;
      line-height: 30px;
    }

    .nav-footer-social {
      margin: 0;
      overflow: hidden;
      text-align: right;
      display: flex;
    }
  }
}

.search-box-container {
  .cx-search-box-v2 {
    width: 100%;
    margin-bottom: 2rem;
  }

  @media only screen and (width >= 56.25em) {
    .cx-search-box-v2 {
      width: 600px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }

  .search-box-results-number {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  @media only screen and (width >= 768px) {
    .search-box-results-number {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

// Melilab Tag

.meliLab__tag {
  z-index: 70;
  width: max-content;
  position: absolute;
  top: 20px;
  left: 145px;

  &__mp {
    left: 350px;
  }
}

.ml-labs-logo {
  display: none;
}

@media screen and (width >= 1216px) {
  .meliLab__tag {
    left: calc(((100vw - 1200px) / 2) + 145px);
    &__mp {
      left: 350px;
    }
  }
}

@media screen and (width <= 1024px) {
  .meliLab__tag__mp {
    left: 150px;
  }
}
