.widget-title {
  margin-bottom: 24px;

  > p {
    margin: 0;
    color: #333;
  }

  p#{&}__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.11;
    margin-bottom: 8px;
  }
  p#{&}__subtitle {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 4px;
  }
  p#{&}__order-number {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.14;
    color: #999;
  }
}
