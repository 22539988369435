// sass-lint:disable bem-depth

.c-activities {
  &__list {
    .ui-row {
      border-bottom: 0.0625em solid #eee;

      .ui-row__link {
        text-decoration: none;
      }

      .ui-row__link:hover {
        background: #f7f7f7;
        color: #333 !important;
      }

      &:last-child {
        border: 0;
      }

      .ui-row__col--content {
        flex-grow: 1;
        overflow: hidden;
        padding-right: 0.5em;

        .u-truncate {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .ui-action-row__description {
          color: #999;
        }
      }

      .ui-row__col--actions {
        .price-tag-symbol-text {
          position: absolute;
          font-size: 0;
        }
      }
    }
  }
}

.c-activity-row--classic .ui-row__link {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
}
