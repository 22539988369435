.status-card {
  background: #f0f0f0;
  width: 100%;
  box-sizing: border-box;
  display: flex;

  &--only-status {
    align-items: center;
  }

  &__icon-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    overflow: visible;

    svg {
      height: 50px;
    }
  }

  &__icon {
  }

  &__detail {
  }

  &__label-status {
    margin: 0 0 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;

    &--success {
      color: #00a650;
    }

    &--warning {
      color: #f73;
    }

    &--error {
      color: #f23d4f;
    }
  }

  &__title {
    margin: 0;
    font-size: 14px;
    line-height: 1.14;
    color: #3a3a3a;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #666;

    > p {
      margin: 0;
    }
  }
}
