// Widget Search
@import './components/StatusCard/styles';
@import './components/Title/styles';
@import './components/Collapsible/styles';
@import './Search/styles';
@import './Payment/payment';
@import './Purchase/purchase';
@import './Product/product';
@import './Item/item';
@import './Sell/sell';
@import './RelatedContents/styles';

.portal-widget {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;

  &__container {
    width: 432px;
    margin-top: 2px;
  }
}

@media screen and (width <= 480px) {
  .portal-view__main--mp .portal-widget__container {
    background-color: #f5f5f5;

    .portal-widget {
      border-top: 0.0625em solid #e6e6e6;
    }
  }

  .portal-widget {
    width: 100%;
    height: auto;
    background-color: #fff;
    flex: 1;

    &__container {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #fff;
    }
  }
}
