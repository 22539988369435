.cx-peach-home__section {
  .cx-peach__widget--related-contents {
    padding: 67px 32px 48px;

    h2 {
      font-size: $font-size-20;
      font-weight: $font-weight-semibold;
      padding-bottom: $andes-spacing-16;
      margin-bottom: 0;
    }
  }
}
