@import '~@andes/breadcrumb/index';

.cx-breadcrumb__wrapper {
  max-width: 745px;
  margin-bottom: $andes-spacing-24;
  align-self: flex-start;

  .breadcrumb {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    .andes-breadcrumb__item {
      list-style: none;
      display: flex;
      overflow: hidden;

      & > .andes-breadcrumb__link, & > .andes-breadcrumb__label {
        font-weight: 400;
      }

      &:first-child {
        min-width: 70px;
      }

      &:nth-child(2) {
        min-width: 40px;
      }

      a,
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.newSearch {
  .faq-wrapper {
    .cx-breadcrumb__wrapper {
      margin-bottom: $andes-spacing-48;
    }
  }
}

@media screen and (width <= 768px) {
  .cx-breadcrumb__wrapper {
    display: none;
  }
}
