@import '~@andes/card/index';
@import '~@andes/list/index';

.cx-contents-list {
  margin-bottom: 32px;

  &__icon {
    align-items: flex-start;
    display: flex;

    svg {
      vertical-align: text-bottom;
    }

    &--center {
      width: 24px;
      height: 22px;
      align-items: center;
    }

    &--top {
      align-self: flex-start;
      margin-top: 1px;
    }
  }

  .andes-card__header {
    padding: 18px 16px;
  }

  .svg-to-component.cx-contents-list__icon {
    margin-right: 12px;
  }

  .cx-list-item {
    line-height: 1.2;

    .andes-list__item-second-column {
      .andes-list__item-chevron {
        align-self: center;
        line-height: 0;
        margin-top: 0;
      }
    }

    svg {
      line-height: 1;
    }
  }

  .tx-list-item {
    .andes-list__item-primary {
      font-weight: bold;
    }
  }

  .andes-list__item.cx-list-item.andes-list__item--size-medium {
    height: auto;
  }
}
