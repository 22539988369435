@import '../../styles/global';
@import '../../styles/variables';

@import '../../components/ContentList/ContentList';
@import '../../components/CardList/CardList';
@import '../../components/Widget/styles';
@import '../../components/CXActivityCard/styles';
@import '../../components/InputSearchV2/InputSearchV2';

// @import '~@activities-ui-components/activity-avatar/styles/desktop';
// @import '~@activities-ui-components/activity-row/styles/styles.small.home';
// @import '~@activities-ui-components/activity-row/styles/styles.medium.home';
// @import '~@activities-ui-components/activity-row/lib/ActionRow/OtherActions/styles.desktop';
@import '../../components/Activities/shared';
@import '~merch-realestates-web/src/realestates-web/index';

.portal-content.folder-wrapper {
  align-items: unset;
  margin-top: $andes-spacing-32;

  .folder {
    &__title {
      padding: 0 0 $andes-spacing-24;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2em;
      -webkit-font-smoothing: antialiased;
    }

    .peach__activities__card {
      margin-bottom: 20px;

      .andes-card__content {
        padding: 0;
      }

      .andes-card__footer {
        padding: 0;
        overflow: hidden;
      }

      .c-activities__list {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }

    .search-box-container {
      .cx-search-box-v2 {
        width: 100%;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
      }

      @media only screen and (width >= 56.25em) {
        .cx-search-box-v2 {
          width: 600px;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.contingency + .cx-contents-list {
  margin-top: calc(56px - 22px);

  // The search section class margin is replicated to keep the view intact when closing the component
}

.contingency + .peach__activities__card {
  margin-top: calc(56px - 22px);

  // The search section class margin is replicated to keep the view intact when closing the component
}

@media screen and (width <= 768px) {
  .portal-content.folder-wrapper {
    background-color: $body_bg_color_gray;
    margin: 0;

    .folder {
      &__title {
        padding: 20px 0;

        &--no-padding {
          padding: 8px 0 20px;
        }
      }

      &__level-title {
        padding-top: 24px;
      }

      .cx-contents-list {
        margin-bottom: 0;
      }
    }
  }
}
